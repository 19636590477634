import React from 'react'
import { Helmet } from 'react-helmet'
import Logo from '../images/logo.png'
import './reset.scss'
import './layout.scss'

const Layout = ({ children, title, description }) => {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <header>
        <div className="logo-container">
          <img src={Logo} alt="Shutterfly logo" />
        </div>
      </header>
      {children}
    </main>
  )
}

Layout.defaultProps = {
  title: 'Shutterfly Studio',
  description: 'Your friendly neighbourhood photographer in Ilford, London',
}

export default Layout
