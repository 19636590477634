import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'
import Layout from '../layout/layout'
import './index.scss'
import { BsInstagram, BsTelephone, BsWhatsapp } from 'react-icons/bs'

// markup
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query AllData {
      allRestApiApiPhotos {
        edges {
          node {
            details {
              description
              name
              photos {
                assetId
                publicId
                url
              }
            }
          }
        }
      }
      allRestApiApiTestimonials {
        edges {
          node {
            details {
              location
              name
              text
            }
          }
        }
      }
    }
  `)

  const testimonials = data?.allRestApiApiTestimonials?.edges
  const photos = data?.allRestApiApiPhotos?.edges

  return (
    <Layout title="Shutterfly Studio | Event Photography | Child Photography">
      <div className="index-container">
        <div className="thumbnail-wrapper">
          {photos?.length > 0
            ? photos.map((album, idx) =>
                album?.node?.details?.photos[0] ? (
                  <div key={idx} className="thumbnail">
                    <div
                      className="img"
                      style={{
                        background: `url(${album.node.details.photos[0].url})`,
                        backgroundSize: `cover`,
                      }}
                    ></div>
                  </div>
                ) : null
              )
            : null}
        </div>

        <h1 className="hero-text">
          Time is unstoppable..
          <br />
          Save your moments in beautiful photographs with Shutterfly Studio. Contact us for a quote
          today.
        </h1>

        {testimonials?.length > 0 && testimonials[0]?.node?.details ? (
          <div className="testimonial-container">
            <h2 className="testimonial">What our clients say</h2>

            {testimonials
              .map((t) => t.node.details)
              .map(({ location, name, text }) => (
                <p key={text}>
                  {text}
                  <br />
                  <span>
                    - {name}
                    {location ? `, ${location}` : null}
                  </span>
                </p>
              ))}
          </div>
        ) : null}

        <div className="testimonial-container">
          <h2 className="testimonial">Contact Us</h2>
          <p className="contacts">
            <a href="https://www.instagram.com/shutterfly.studios/" target="_blank">
              <BsInstagram />
            </a>
            <a href="https://wa.me/447511708969" target="_blank">
              <BsWhatsapp />
            </a>
            <a href="tel:+44-7511708969" target="_blank" alt="+44-7511708969">
              <BsTelephone />
            </a>
          </p>
        </div>

        <div className="copyright">&copy; {new Date().getFullYear()} Shutterfly Studio</div>
      </div>
    </Layout>
  )
}

export default IndexPage
